<template>
  <section>
    <b-row>
      <b-col md="3" lg="3" v-for="stat in stats" :key="stat.id">
        <stat :variant="stat.variant" :icon="stat.icon" :name="stat.name" :value="stat.value" :unit="stat.unit"></stat>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <bruna-element-loader :dataUrl="quartilesAsertividadChart00" loadingStrategy="ALWAYS">
          </bruna-element-loader>
      </b-col>
      <!--
      <b-col>
        <bruna-element-loader :dataUrl="quartilesAsertividadChart01" loadingStrategy="ALWAYS">
          </bruna-element-loader>
      </b-col>
      <b-col>
        <bruna-element-loader :dataUrl="quartilesAsertividadChart02" loadingStrategy="ALWAYS">
          </bruna-element-loader>
      </b-col>
      -->
    </b-row>

    <!--
    <b-row>

      <b-col>

        <div>
          <b-overlay :show="showOverlay" rounded="sm">
            <b-card no-body>
              <div class="card-header">
                
                <div>
                  <b-card-title></b-card-title>
                  <b-card-sub-title></b-card-sub-title>
                </div>

                
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>

              
              <b-card-body>
                <ag-grid-vue style="width: 100%; height: 500px" class="ag-theme-alpine" :columnDefs="columnDefs"
                  :rowData="rowData" :pinnedTopRowData="pinnedTopRows"></ag-grid-vue>
              </b-card-body>
            </b-card>

            <template #overlay>
              <div class="text-center">
                <b-spinner label="Loading..." />
                <p id="cancel-label">Cargando Resultados...</p>
              </div>
            </template>
          </b-overlay>
        </div>


      </b-col>


    </b-row>
    -->

    <b-row v-if="debug">
      <b-col>
        <bruna-element-loader :dataUrl="asertividadPorMesChart00" loadingStrategy="ALWAYS">
          </bruna-element-loader>
      </b-col>
      
    </b-row>


    <b-row>
        <b-col sm="12">
          <ag-grid-table
            :configUrl="realVsPrediccionTable2Config"
            :dataUrl="realVsPrediccionTable2Data"
          >
            
          </ag-grid-table>
        </b-col>
      </b-row>



  </section>
</template> 
        
<script>

import useApiServices from '@/services/useApiServices'
import { AgGridVue } from "ag-grid-vue";
import Stat from '@/views/clients/base/components/Stat.vue'
import axios from '@axios'
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

var qs = require('qs');

import
{
  BTabs,
  BTab,
  BOverlay,
  BSpinner
} from "bootstrap-vue";



export default {
  components: {
    BTabs,
    BTab,
    BOverlay,
    BSpinner,
    AgGridVue,
    Stat,
    BrunaElementLoader,
    AgGridTable
  },

  methods: {

    loadStats()
    {
      axios.request({
        method: "get",
        url: this.stats00Url,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) =>
        {
          this.stats = response.data
        })




    },

    loadTable()
    {
      axios.request({
      method: "get",
      url: useApiServices.historicoCompareRealVsPrediccionTable,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) =>
      {

        this.showOverlay = false;
        this.columnDefs = response.data.columnDefs;
        this.rowData = response.data.rowData;
        this.pinnedTopRows = response.data.pinnedTopRows;

      })




    },    



  },

  mounted()
  {
    //this.loadTable()
    this.loadStats()
    
  },




  created()
  {

  },


  data()
  {

    let debug = (this.$route.query.debug == '1')

    return {
      debug: true,
      showOverlay: true,
      columnDefs: [],
      rowData: [],  
      pinnedTopRows: [],

      stats: [],
      stats00Url: useApiServices.historicoCompareRealVsPrediccionStats,

      quartilesAsertividadChart00: useApiServices.quartilesAsertividadChart00 + '?modelo=canias_prediccion_rendimiento_v5',
      quartilesAsertividadChart01: useApiServices.quartilesAsertividadChart00 + '?modelo=canias_prediccion_rendimiento_v5',
      quartilesAsertividadChart02: useApiServices.quartilesAsertividadChart00 + '?modelo=canias_prediccion_rendimiento_v5',

      asertividadPorMesChart00: useApiServices.asertividadPorMesChart00 + '?modelo=canias_prediccion_rendimiento_v5',

      realVsPrediccionTable2Config: useApiServices.realVsPrediccionTable2Config,
      realVsPrediccionTable2Data: useApiServices.realVsPrediccionTable2Data


    };
  },

};


window.dateComparator = function dateComparator(valueA, valueB, nodeA, nodeB, isInverted){ let dateValueA = Date.parse(valueA); let dateValueB = Date.parse(valueB); return dateValueA - dateValueB; };

</script>

<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>
      
      
<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
  
<style scoped>
.btn-padding {
  margin-right: 48px;
}

.m-estadisticas {
  margin-left: 20px;
  margin-bottom: 1%;
  margin-top: 1%;
}

.icon-plan {
  padding-top: 5%;
}
</style>
  

<style>
div.ag-watermark {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
        